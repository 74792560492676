import React from "react";
import { glProjectStore } from "../Stores";

export const apiUrl = "https://kotylus-web-api.onrender.com";

export const fetchGitlabProjects = async() => {
  const data = await fetch(`${apiUrl}/gitlab/projects`);
  const rawData = await data.json();
  const curedData = rawData.map(project => {
    let desc = project.description;
    if (!desc) {
      desc = "";
    }
    return {
      ...project,
      description: desc
    };
  });
  glProjectStore.update(store => {
    store.projects = curedData
  });
};
