import React from "react";
import { ProjectComponent } from "../components";
import { glProjectStore } from "../Stores";

const ProjectPage = () => {
  const gitlab = glProjectStore.useState();
  return (
    <div className="scroll-y main-scrollbar project-container">
      {gitlab.displayedProjects
        .filter(project => 
          (project.name.toLowerCase().includes(gitlab.searchTerm.toLowerCase())) ||
          (project.description.toLowerCase().includes(gitlab.searchTerm.toLowerCase()))
        ).map(project =>
          <ProjectComponent key={project._id} project={project} />
        )
      }
    </div>
  )
};


export default ProjectPage;
