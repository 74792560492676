import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes as Switch } from "react-router-dom";
import { TopBar, MenuController } from "./components";
import { ProjectPage, WriteUpPage, MarkdownPage, AboutPage } from "./pages"
import { glProjectStore, markdownStore } from "./Stores";
import { fetchGitlabProjects } from "./api/glProjects";

const App = () => {
  const gitlab = glProjectStore.useState();
  const markdown = markdownStore.useState();

  //tag filter
  useEffect(() => {
    const enabled = [];
    const res = [];
    //loads active tags
    gitlab.tags.map(tag => {
      if (tag.enabled) {
        enabled.push(tag.name);
      }
    });
    //if empty returns all
    if (!enabled.length) {
      glProjectStore.update((store) => {
        store.displayedProjects = gitlab.projects
      });
      return;
    }
    //filtering with duplicite check
    gitlab.projects.map(project => {
      if (project.tags.filter(tag => enabled.includes(tag)).length) {
        if (!res.includes(project)) {
          res.push(project);
        }
      }
    });
    glProjectStore.update(store => {
      store.displayedProjects = res
    });
  },  [gitlab.tags]);

  //tag load
  useEffect(() => {
    const unique = [];
    const result = []
    //grab unique
    gitlab.projects.map(project => {
      const tgs = project.tags
      tgs.map(tag => {
        if (!unique.includes(tag)) {
          unique.push(tag)
        }
      });
    });
    unique.map(tag => {
      result.push({"name": tag, "enabled": false})
    });
    glProjectStore.update(store => {
      store.tags = result;
    });
  }, [gitlab.projects]);
  
  //INIT display projects
  useEffect(() => {
    console.log(gitlab.projects);
    glProjectStore.update(store => {
      store.displayedProjects = gitlab.projects
    });
  }, [gitlab.projects]);

  //INIT gitlab projects
  useEffect(() => {
    (async() => {
      await fetchGitlabProjects();
    })();
  }, []);

  //load Markdown files names
  useEffect(() => {
    let tmp = [];
    for (let x in markdown.writeups) {
      tmp.push(x);
    }
    markdownStore.update(store => {
      store.fileNames = tmp;
    });
  }, []);

  return (
    <div className="app">
      <Router>
        <MenuController />
        <main>
          <div className="h-col-center">
            <TopBar />
            <Switch>
              <Route path="/" exact element={<ProjectPage />} />
              <Route path="/about" exact element={<AboutPage />} />
              <Route path="/writeups" exact element={<WriteUpPage />} />
              <Route path="/writeups/:name" element={<MarkdownPage />} />
            </Switch>
          </div>
        </main>
      </Router>
    </div>
  );
};

export default App;