import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { markdownStore } from "../Stores";


const AboutPage = () => {

  const markdown = markdownStore.useState();

  useEffect(() => {
    fetch(markdown.aboutme)
      .then(response => (
        response.text()
      ))
      .then(text => (
        markdownStore.update(store => {
          store.displayedMD = text;
        })
      ))
  }, []);

  return (
    <div>
      <ReactMarkdown children={markdown.displayedMD} />
    </div>
  );
}

export default AboutPage;