import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { markdownStore } from "../Stores";


const MarkdownPage = () => {

  const { name } = useParams();
  const markdown = markdownStore.useState();

  useEffect(() => {
    fetch(markdown.writeups[name])
      .then(response => (
        response.text()
      ))
      .then(text => {
        markdownStore.update(store => {
          store.displayedMD = text
        })
      })
  }, [name]);


  return (
    <div style={{paddingBottom: "5vh"}}>
      <ReactMarkdown children={markdown.displayedMD} />
    </div>
  );
}

export default MarkdownPage;