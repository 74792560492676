import { Store } from "pullstate";
import * as writeups from "./pages/writeups";
import aboutpage from "./pages/writeups/AboutPage.md";

export const glProjectStore = new Store({
  projects: [],
  displayedProjects: [],
  tags: [],
  searchTerm: ""
});

export const appStateStore = new Store({
  isMenuOpen: false,
  isSearchOpen: false
});

export const markdownStore = new Store({
  aboutme: aboutpage,
  writeups: writeups,
  fileNames: [],
  displayedMD: ""
});
