import React, { useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { isOnProjectPage } from "../pages/utils";
import { appStateStore, glProjectStore } from "../Stores";

const MenuOpenComponent = ({ toggleMenu }) => {

  const gitlab = glProjectStore.useState();
  const location = useLocation();

  const toggleTag = useCallback((tag) => {
    const temp = [...gitlab.tags];
    temp.splice(temp.indexOf(tag), 1, {
      ...tag,
      "enabled": !tag.enabled
    });

    glProjectStore.update(store => {
      store.tags = temp;
    });
  }, [gitlab.tags]);

  return (
    <header className="openHeader">
      <div>
        <svg onClick={toggleMenu} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
      </div>
      <nav className="nav-container">
        <nav>
          <Link to="/">Home</Link>
          <Link to="/about">About me</Link>
          <Link to="/writeups">Write ups</Link>
        </nav>
        <nav className="scroll-y header-scrollbar">
          {isOnProjectPage(location)
            ? gitlab.tags.map(tag =>
              <p
                key={tag.name}
                onClick={() => toggleTag(tag)}
                style={tag.enabled ? {color: "#f47802"} : {}}
              >
                {tag.name}
              </p>
            )
            : null
          }
        </nav>
      </nav>
    </header>
  )
};

const MenuCloseComponent = ({ toggleMenu }) => (
  <header className="closedHeader" onClick={toggleMenu}>
    <div>
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" className="svg-inline--fa fa-bars fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>
    </div>
  </header>
);

export const MenuController = () => {

  const appState = appStateStore.useState();

  const toggleMenu = useCallback(() => {
    appStateStore.update(store => {
      store.isMenuOpen = !appState.isMenuOpen;
    });
  }, [appState.isMenuOpen]);

  return (
    appState.isMenuOpen 
      ? <MenuOpenComponent toggleMenu={toggleMenu} />
      : <MenuCloseComponent toggleMenu={toggleMenu} />
  )
}

