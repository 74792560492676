import React from "react";
import { Link } from "react-router-dom";
import { markdownStore } from "../Stores";

const WriteUpPage = () => {
  const markdown = markdownStore.useState();

  //<ReactMarkdown children={markdown} />

  return (
    <div>
      <h3 style={{fontSize:"1.5rem"}}>Welcome on my write up page. Here i dump my writeups from ctfs and learning rooms.</h3>
      <ul style={{fontSize:"1.3rem"}}>
        {markdown.fileNames.map(name => (
          <li key={name}>
            <Link to={`/writeups/${name}`}>{name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default WriteUpPage;
